<template>
  <div class="income_and_expenditure_detail">
    <main>
      <div class="search">
        <div class="left">
          <van-popover
            v-model="showPopover"
            theme="dark"
            trigger="click"
            :actions="actions"
            @select="selectDate"
            :offset="[0, 12]"
            className="date_menu"
          >
            <template #reference>
              <span
                >{{ actions[actionsIndex].text
                }}<svg-icon icon-class="Check box_icon"
              /></span>
            </template>
          </van-popover>
          <div class="day">
            <span class="day2" v-show="actionsIndex !== 1">{{
              actions[actionsIndex].day2 | date
            }}</span>
            <span v-show="actionsIndex === 2 || actionsIndex === 3">-</span>
            <span class="day1">{{ actions[actionsIndex].day1 | date }}</span>
          </div>
        </div>
        <div class="right">
          <van-dropdown-menu active-color="#009A4D" >
            <van-dropdown-item title="筛选" ref="type" @close="typeClose" > 
              <template #default>
                <div class="content">
                  <div class="type">
                    <h3 class="title">收支类型</h3>
                    <ul>
                      <li
                        v-for="(item, index) in payRevType.item"
                        :key="index"
                        :class="index === payRevType.index ? 'pitch_on' : ''"
                        @click="
                          index === payRevType.index
                            ? (payRevType.index = -1)
                            : (payRevType.index = index)
                        "
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                  <div class="type">
                    <h3 class="title">交易类型</h3>
                    <ul>
                      <li
                        v-for="(item, index) in tradeType.item"
                        :key="index"
                        :class="index === tradeType.index ? 'pitch_on' : ''"
                        @click="
                          index === tradeType.index
                            ? (tradeType.index = -1)
                            : (tradeType.index = index)
                        "
                      >
                        {{ item.name ? item.name : item }}
                      </li>
                    </ul>
                  </div>
                  <div class="search_input">
                    <h3 class="title">商户订单号</h3>
                    <van-search
                      v-model="code"
                      shape="round"
                      placeholder="搜索商户订单号"
                    />
                  </div>
                </div>
                <div class="controls">
                  <van-button
                    @click="[(payRevType.index = -1), (tradeType.index = -1)]"
                    >重置</van-button
                  >
                  <van-button color="#009A4D" @click="submit">确定</van-button>
                </div>
              </template>
            </van-dropdown-item>
          </van-dropdown-menu>
        </div>
      </div>
      <div class="detail">
        <van-list
          v-model="loading"
          :finished="finished"
          :error.sync="error"
          :finished-text="detail.length ? '没有更多了' : ''"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
          offset="10"
        >
          <div class="all_price">
            <div>
              <p>
                收入金额(元)：<b>{{
                  detail.length
                    ? (detail[0].revTotalAmount / 100).toFixed(2)
                    : "0.00"
                }}</b>
              </p>
              <p>
                收入笔数(笔)：<b>{{
                  detail.length ? detail[0].revNumber : "0"
                }}</b>
              </p>
            </div>
            <div>
              <p>
                支出金额(元)：<b>{{
                  detail.length
                    ? (detail[0].payTotalAmount / 100).toFixed(2)
                    : "0.00"
                }}</b>
              </p>
              <p>
                支出笔数(笔)：<b>{{
                  detail.length ? detail[0].payNumber : "0"
                }}</b>
              </p>
            </div>
          </div>
          <div class="detail_list">
            <div v-for="item in detail" :key="item.createdAt">
              <h4>
                {{ item.payRevType === "收入" ? "分佣入账" : item.tradeType }}
                <!-- {{ item.code }} -->
              </h4>
              <b class="pirce"
                >{{ item.payRevType === "收入" ? "+" : "-"
                }}{{ (item.amount / 100).toFixed(2) }}
              </b>
              <p class="time">{{ item.date }}</p>
            </div>
          </div>
        </van-list>
        <van-empty
          description="暂无明细"
          v-if="!loading && finished && !error && !detail.length"
        />
      </div>
    </main>
  </div>
</template>

<script>
import { formatDay2, formatDate } from "@/utils/validate";
import { mapState } from "vuex";
export default {
  name: "income-and-expenditure-detail",

  data() {
    return {
      showPopover: false,
      actions: [
        { text: "全部", className: "date_menu" },
        { text: "今天", day1: 0, day2: 0, className: "date_menu" },
        { text: "最近7天", day1: 0, day2: 0, className: "date_menu" },
        { text: "最近30天", day1: 0, day2: 0, className: "date_menu" },
      ],
      actionsIndex: 0,
      payRevType: {
        index: -1,
        item: ["收入", "支出"],
      },
      payRevVal: -1,
      tradeType: {
        index: -1,
        item: [
          {
            name: "交易收入",
            val: "分账入账",
          },
          "提现",
          "充值",
          "退款",
        ],
      },
      tradeVal: -1,
      ok: false,
      code: "",

      loading: false,
      finished: false,
      error: false,
      detail: [],
      pageNum: 1,
      pages: 1,
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  filters: {
    date(val) {
      return formatDay2(val);
    },
  },
  created() {
    this.getDate();
  },
  methods: {
    getDate() {
      // 获取当前时间戳
      var todayTimestamp = new Date().getTime();
      // 获取今日0点时间
      var todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      // 获取今日0点时间戳（毫秒级）
      var todayStartTimestamp = todayStart.getTime();
      // 获取最近七天的时间戳
      var sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      var sevenDaysAgoTimestamp = sevenDaysAgo.getTime();
      // 获取最近30天的时间戳
      var thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      var thirtyDaysAgoTimestamp = thirtyDaysAgo.getTime();

      this.actions[1].day1 = todayTimestamp;
      this.actions[1].day2 = todayStartTimestamp;
      this.actions[2].day1 = todayTimestamp;
      this.actions[2].day2 = sevenDaysAgoTimestamp;
      this.actions[3].day1 = todayTimestamp;
      this.actions[3].day2 = thirtyDaysAgoTimestamp;
    },
    selectDate(action, i) {
      if (this.actionsIndex === i) return;
      this.actionsIndex = i;
      this.onLoad(true);
    },
    submit() {
      this.ok = true;
      this.$refs.type.toggle();
    },
    typeClose() {
      if (this.ok) {
        this.tradeVal = this.tradeType.index;
        this.payRevVal = this.payRevType.index;
        this.ok = false;
        this.onLoad(true);
      } else {
        this.tradeType.index = this.tradeVal;
        this.payRevType.index = this.payRevVal;
      }
    },
    async onLoad(refresh) {
      if (refresh) {
        this.pageNum = 1;
        this.pages = 1;
        this.detail = [];
        this.finished = false;
        this.error = false;
        this.loading = true;
      }
      await this.$API.user
        .payRevDetailsQuery({
          object: {
            bankType: 2,
            code: this.code,
            endTime: this.actions[this.actionsIndex].day1,
            id: this.userInfo.id,
            payRevType:
              this.payRevVal > -1 ? this.payRevType.item[this.payRevVal] : "",
            startTime: this.actions[this.actionsIndex].day2,
            tradeType:
              this.tradeVal > -1
                ? this.tradeType.item[this.tradeVal].name
                  ? this.tradeType.item[this.tradeVal].val
                  : this.tradeType.item[this.tradeVal]
                : "",
          },
          pageNum: this.pageNum,
          pageSize: 6,
        })
        .then((res) => {
          if (res.data.code != "SUCCESS") throw Error(res.data.msg);
          this.pages = res.data.pages;
          console.log(res);
          res.data.data.forEach((e) => {
            e.date = formatDate(e.finishTime);
            this.detail.push(e);
          });
          this.loading = false;
          if (this.pageNum >= this.pages) {
            this.finished = true;
          } else {
            this.pageNum++;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.income_and_expenditure_detail {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 46px;

  main {
    flex: 1;
    .search {
      background: #fff;
      margin: 0 0 10px;
      display: flex;
      align-items: center;
      padding: 18px 15px;
      .left {
        flex: 1;
        display: flex;
        align-items: center;
        border: 1px solid #707070;
        border-radius: 99px;
        padding: 5px 0;
        .van-popover__wrapper {
          span {
            display: inline-block;
            width: 100px;
            text-align: center;
            .svg-icon {
              font-size: 10px;
              color: #848484;
              vertical-align: unset;
              margin-left: 4px;
            }
          }
        }
        .day {
          flex: 1;
          text-align: center;
          span:nth-child(2) {
            margin: 0 5px;
          }
        }
      }
      ::v-deep .right {
        .van-dropdown-menu__bar {
          box-shadow: none;
          height: auto;
          margin-left: 4px;
        }
        .van-dropdown-item {
          top: 113px !important;
          .van-overlay {
            background: rgba(51, 51, 51, 0.39);
          }
          .van-popup {
            .content {
              padding: 15px;
              .type {
                ul {
                  display: flex;
                  flex-wrap: wrap;
                  margin: 0 -14px;
                  li {
                    border: 1px solid #707070;
                    color: #707070;
                    transition: 0.2s all;
                    border-radius: 99px;
                    width: calc(100% / 3 - 12 * 3px);
                    margin: 0 18px 12px;
                    padding: 8px 0;
                    text-align: center;
                  }
                  .pitch_on {
                    background: #009a4d;
                    border-color: #009a4d;
                    color: #fff;
                  }
                }
              }
              .search_input {
                .van-search {
                  padding: 0;
                  border: 1px solid #707070;
                  border-radius: 99px;
                  .van-search__content {
                    background-color: #fff;
                  }
                }
              }
              .title {
                margin-bottom: 18px;
                font-size: 14px;
              }
            }
            .controls {
              margin-top: 15px;
              .van-button {
                width: 50%;
              }
            }
          }
        }
      }
    }
    .detail {
      .van-list {
        .all_price {
          background: #fff;
          padding: 18px 15px;
          display: flex;
          justify-content: space-between;
          position: relative;
          div {
            p {
              color: #666666;
              font-size: 12px;
              display: flex;
              align-items: center;
              b {
                color: #333333;
                font-size: 17px;
              }
            }
          }
        }
        .all_price::after {
          content: "";
          position: absolute;
          border-bottom: 1px solid #f2f2f2;
          bottom: 0;
          left: 15px;
          right: 15px;
        }
        .detail_list {
          background: #fff;
          padding: 0 15px;
          div {
            padding: 20px 0;
            border-bottom: 1px solid #f2f2f2;
            h4 {
              margin-bottom: 10px;
              font-size: 16px;
              color: #333333;
            }
            b {
              display: inline-block;
              margin-bottom: 10px;
              color: #fb4e10;
              font-size: 16px;
            }
            .time {
              color: #999;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.van-popup .date_menu {
  width: 87px;
  padding: 0;
}
</style>
